import React from 'react';
import { array } from 'prop-types';

import NumberFormat from 'react-number-format';

function Profile(props) {
    const { items } = props;

    const profileItems = items.map(item => (
        <li className={classes.item} key={item.label}>
            <span className={classes.label}>{item.label}</span>
            <div className={classes.flexSpace} />
            <NumberFormat
                className={classes.value}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$ '}
                value={item.value}
                displayType={'text'}
            />
        </li>
    ));

    return <ul className={classes.profileWrapper}>{profileItems}</ul>;
}

import classes from './profile.css';
Profile.propTypes = {
    items: array
};

export default Profile;
